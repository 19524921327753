import PropTypes from "prop-types"
import React from "react"

const Header = ({
  siteTitle,
  isForSubPages = false,
}: {
  siteTitle?: string
  isForSubPages?: boolean
}) => (
  <header className={`main-header ${isForSubPages ? "isForSubPages" : ""}`}>
    <nav>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <div className="dropdown">
            <button className="dropbtn">Features</button>
            <div className="dropdown-content">
              <a href="#">Transparency & structure</a>
              <a href="#">Connect Jira, GitLab & GitHub </a>
              <a href="#">Don’t get lost in your documents anymore</a>
            </div>
          </div>
        </li>
        {/*<li>*/}
        {/*  <a href="#features">Features</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <a href="/about">About</a>*/}
        {/*</li>*/}
        <li>
          <a href="/blog">Blog</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
