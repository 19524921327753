/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
// import Header from "./header";
import "./layout.scss"
import Header from "./header"

const Layout = ({
  children,
  isHideHeader,
}: {
  children: any
  isHideHeader?: boolean
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {isHideHeader ? "" : <Header isForSubPages={true} />}
      <main>{children}</main>
      {/*/!*<Footer>*!/© {new Date().getFullYear()} Johannes Millan*/}
      {` `}
      {/*<a href="/">Impressum</a>*/}
      {/*</Footer>*/}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
